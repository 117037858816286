import { defineStore } from 'pinia';
import useRemoteResource from '@/hooks/useRemoteResource';
import useCadreApi from '@/hooks/cadre/useCadreApi';

const { contract } = useCadreApi();

export default defineStore('cadreContract', () => {
  const { state, fetch, fetched } = useRemoteResource('cadre.contract', contract.fetch);

  return {
    state,
    fetch,
    fetched,
  };
});
