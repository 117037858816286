<template>
  <b-card
    class="cadre-edit-employee"
    >

    <b-tabs pills card>

      <b-tab active>
        <template #title>
          <feather-icon icon="SlidersIcon" />
          Ustawienia pracownika
        </template>

        <main-settings />
      </b-tab>

      <!-- TODO -->
      <b-tab lazy>
        <template #title>
          <feather-icon icon="UserIcon" />
          Ustawienia konta i uprawnienia
        </template>

        <account-settings />
      </b-tab>

    </b-tabs>
  </b-card>
</template>

<script>
import {
  BCard,
  BTabs,
  BTab,
} from 'bootstrap-vue';
import MainSettings from '@/components/views/cadre/edit-tabs/MainSettings.vue';
import AccountSettings from '@/components/views/cadre/edit-tabs/AccountSettings.vue';
import { computed } from 'vue';
import store from '@/store';

export default {
  setup() {
    const employee = computed(() => store.getters['cadre/employees/current']);

    return { employee };
  },
  components: {
    BCard,
    BTabs,
    BTab,

    MainSettings,
    AccountSettings,
  },
  created() {
    this.$store.dispatch('cadre/employees/fetchOne', { employeeId: this.$route.params.id });
  },
};
</script>
