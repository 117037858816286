<template>
  <div class="form-action-buttons">

    <!--
      TODO: Refactor and merge this component
            with VsModalFooter (along with event unification)
    -->
    <div class="button-group">
      <vs-button
        @click="cancel"
        vs-variant="light">
        {{ labelCancel }}
      </vs-button>

      <vs-button
        @click="submit"
        vs-variant="primary">
        {{ labelSubmit }}
      </vs-button>
    </div>

  </div>
</template>

<script>
import i18n from '@/libs/i18n';
import VsButton from '@/components/ui/vs-button/VsButton.vue';

export default {
  props: {
    labelCancel: {
      type: String,
      default: i18n.t('global.button.cancel'),
    },
    labelSubmit: {
      type: String,
      default: i18n.t('global.button.save'),
    },
  },
  methods: {
    cancel() {
      this.$emit('cancel');
    },
    submit() {
      this.$emit('submit');
    },
  },
  components: {
    VsButton,
  },
};
</script>

<style lang="sass" scoped>
.form-action-buttons
  margin-top: 30px
  width: 100%
  display: flex
  align-items: center
  border: 1px solid rgba(0, 0, 0, 0.1)
  padding: 10px
  border-radius: 5px

  .button-group
    margin-left: auto
    display: flex
    align-items: center
    gap: 10px
</style>
