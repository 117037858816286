<template>
  <div class="employee-form">
    <b-form @submit.prevent>
      <b-row>
        <b-col cols="12">
          <b-form-group
            :label="$t('cadre.employee.edit.field.forename')"
            label-cols-md="3"
            :invalid-feedback="errors.forename"
            :state="errors.forename === null"
            >
            <b-input-group>
              <b-form-input
                :placeholder="$t('cadre.employee.edit.field.forename')"
                v-model="forename"/>
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-form-group
            :label="$t('cadre.employee.edit.field.surname')"
            label-cols-md="3"
            :invalid-feedback="errors.surname"
            :state="errors.surname === null"
            >
            <b-input-group>
              <b-form-input
                :placeholder="$t('cadre.employee.edit.field.surname')"
                v-model="surname"/>
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-form-group
            :label="$t('cadre.employee.edit.field.shortcode')"
            label-cols-md="3"
            :invalid-feedback="errors.short_code"
            :state="errors.short_code === null"
            >
            <b-input-group>
              <b-form-input
                :placeholder="$t('cadre.employee.edit.field.shortcode')"
                v-model="employee.short_code" />
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-form-group
            :label="$t('cadre.employee.edit.field.phone')"
            label-cols-md="3"
            :invalid-feedback="errors.phone_number"
            :state="errors.phone_number === null"
            >
            <b-input-group>
              <b-form-input
                :placeholder="$t('cadre.employee.edit.field.phone')"
                v-model="employee.phone_number"/>
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-form-group
            :label="$t('cadre.employee.edit.field.email')"
            label-cols-md="3"
            :invalid-feedback="errors.email"
            :state="errors.email === null"
            >
            <b-input-group>
              <b-form-input
                :placeholder="$t('cadre.employee.edit.field.email')"
                v-model="employee.email"/>
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-form-group
            :label="$t('cadre.employee.edit.field.status')"
            label-cols-md="3"
            :invalid-feedback="errors.status"
            :state="errors.status !== null"
            >
            <cadre-status-picker
              v-model="employee.status_id"
            />
          </b-form-group>
        </b-col>

        <b-col cols="6">
          <b-form-group
            :label="$t('cadre.employee.edit.field.contract_beginning')"
            label-cols-md="12"
            :invalid-feedback="errors.contract_start"
            :state="errors.contract_start === null"
            >
            <vs-datepicker
              v-model="contractStart"
            />
          </b-form-group>
        </b-col>

        <b-col cols="6">
          <b-form-group
            :label="$t('cadre.employee.edit.field.contract_end')"
            label-cols-md="12"
            :invalid-feedback="errors.contract_end"
            :state="errors.contract_end === null"
            >
            <vs-datepicker
              v-model="contractEnd"
              placeholder="Czas nieokreślony"
              reset-button
              label-reset-button="Czas nieokreślony"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-form-group
            :label="$t('cadre.employee.edit.field.form_of_employment')"
            label-cols-md="3"
            :invalid-feedback="errors.form_of_employment"
            :state="!errors.form_of_employment"
          >
            <cadre-employment-form-picker
              multiple
              v-model="employmentFormsArray"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-form-group
            :label="$t('cadre.employee.edit.field.medical_examinations')"
            label-cols-md="3"
            :invalid-feedback="errors.medical_examinations"
            :state="errors.medical_examinations === null"
            >
            <b-form-checkbox switch
              size="lg"
              v-model="employee.medical_exam_required">
            </b-form-checkbox>
          </b-form-group>
        </b-col>

        <!--
        <b-col cols="12">
          <b-form-group
            :label="$t('cadre.employee.edit.field.group')"
            label-cols-md="3"
            :invalid-feedback="errors.group"
            :state="errors.group !== null"
            >

            <group-picker v-model="group"/>
          </b-form-group>
        </b-col>
        -->

        <b-col cols="12">
          <b-form-group
            :label="$t('cadre.employee.edit.field.position')"
            label-cols-md="3"
            :invalid-feedback="errors.position_id"
            :state="!errors.position_id"
            >
            <cadre-position-picker
              v-model="employee.position_id"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-form-group
            :label="$t('cadre.employee.edit.field.supervisor')"
            label-cols-md="3"
            :invalid-feedback="errors.supervisor"
            :state="errors.supervisor === undefined"
          >
            <cadre-employee-picker
              v-model="employee.supervisor_id"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-form-group
            :label="$t('cadre.employee.edit.field.region')"
            label-cols-md="3"
            :invalid-feedback="errors.region"
            :state="errors.company_branch_id !== null"
            >
            <core-branch-picker v-model="employee.company_branch_id"/>
          </b-form-group>
        </b-col>

        <b-col v-if="!hideUserCheckbox">
          <b-form-group
            :label="$t('cadre.employee.edit.field.createAccount')"
            label-cols-md="3"
            :invalid-feedback="errors.should_create_user"
            :state="errors.should_create_user === null"
            >
            <b-form-checkbox switch
                             size="lg"
              v-model="employee.should_create_user">
            </b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import formatDate from '@/libs/date-formatter';
import i18n from '@/libs/i18n';
import { mapGetters } from 'vuex';
import {
  BCol,
  BRow,

  BForm,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BInputGroup,
} from 'bootstrap-vue';

import CadrePositionPicker from '@/components/module/cadre/CadrePositionPicker.vue';
import CoreBranchPicker from '@/components/module/core/CoreBranchPicker.vue';
import CadreEmploymentFormPicker from '@/components/module/cadre/CadreEmploymentFormPicker.vue';
import CadreStatusPicker from '@/components/module/cadre/CadreStatusPicker.vue';
import CadreEmployeePicker from '@/components/module/cadre/CadreEmployeePicker.vue';
import VsDatepicker from '@/components/ui/vs-datepicker/VsDatepicker.vue';

export default {
  components: {
    BCol,
    BRow,

    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BInputGroup,

    CadrePositionPicker,
    CoreBranchPicker,
    CadreEmploymentFormPicker,
    CadreStatusPicker,
    CadreEmployeePicker,
    VsDatepicker,
  },
  watch: {
    feedback() {
      const errors = this.feedback;
      Object.keys(errors).forEach((key) => {
        if (errors[key].length > 0) {
          [errors[key]] = errors[key];
        }
      });

      this.errors = errors;
    },
    employee: {
      handler() {
        this.emitEmployee();
        if (!this.employee.id) return;

        if (!this.employee.assigned_id) this.employee.assigned_id = this.user.id;
      },
      deep: true,
    },
    model: {
      handler() {
        this.employee = this.model;
      },
      deep: true,
    },
  },
  props: {
    // checkbox is not neccessary in e.g. employee edit
    hideUserCheckbox: {
      type: Boolean,
      default: false,
    },
    model: {
      type: Object,
      default: () => ({
        short_code: null,
        assigned_id: null,
        priority_id: 2,
        status_id: 1,
        contract_start: formatDate.standard(Date.now()),
        should_create_user: false,
        medical_exam_required: true,
        form_of_employment: [],
      }),
    },
    value: {
      type: Object,
    },
    feedback: {
      type: Object,
    },
  },
  data() {
    return {
      employee: { ...this.model },
      positionLocalId: null,
      groupLocalId: null,
      errors: {},
      options: {
        date: {
          date: true,
          delimiter: '-',
          datePattern: ['d', 'm', 'Y'],
        },
      },
    };
  },
  created() {
    if (!this.employee.assigned_id) this.employee.assigned_id = this.user.id;

    this.groupLocalId = this.employee.group?.id;
    this.positionLocalId = this.employee.position?.id;
    this.employmentFormsArray = this.employee.form_of_employment;

    this.emitEmployee();
  },
  methods: {
    emitEmployee() {
      this.$emit('input', {
        ...this.employee,
        form_of_employment: this.employmentFormsArray,
      });
    },
    generateShortcode() {
      const { forename, surname } = this.employee;

      const shortcode = `${forename?.substr(0, 1) || ''}${surname?.substr(0, 2) || ''}`;
      this.employee.short_code = shortcode
        .toUpperCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '');
    },
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
    forename: {
      get() {
        return this.employee.forename;
      },
      set(value) {
        const forename = `${this.employee.forename}`;
        this.employee.forename = value;

        if (value !== forename) {
          this.generateShortcode();
        }
      },
    },
    employmentFormsArray: {
      get() {
        const forms = this.employee?.form_of_employment;
        if (!forms) return [];
        return Object.keys(forms).map((form) => forms[form]?.id ?? forms[form]);
      },
      set(val) {
        this.employee.form_of_employment = val;
      },
    },
    surname: {
      get() {
        return this.employee.surname;
      },
      set(value) {
        const surname = `${this.employee.surname}`;
        this.employee.surname = value;

        if (value !== surname) {
          this.generateShortcode();
        }
      },
    },
    calendarTranslations: () => i18n.t('global.calendar'),
    contractStart: {
      get() {
        return formatDate.standard(this.employee.contract_start);
      },
      set(newDate) {
        this.employee.contract_start = newDate;
      },
    },
    contractEnd: {
      get() {
        return formatDate.standard(this.employee.contract_end);
      },
      set(newDate) {
        this.employee.contract_end = newDate;
      },
    },
  },
};
</script>

<style lang="sass" scoped>
.employee-form
  form
    padding: 30px 45px

    @media screen and (max-width: 991px)
      padding: 0px 0px

.employee-form::v-deep
  .vs-wysiwyg
    margin-top: 15px
</style>
