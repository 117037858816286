<template>
  <div class="cadre-edit-employee-main">

    <b-container v-if="Object.keys(currentEmployee).length">
      <b-row>

        <b-col cols="2">
          <div>
            <avatar
              lg
              :user="currentEmployee"
              :displayUserIcon="!!currentEmployee.user_core_id"
            />
          </div>
        </b-col>

        <b-col cols="8">
          <employee-form
            hide-user-checkbox
            v-model="newEmployee"
            v-if="Object.keys(currentEmployee).length"
            :model="currentEmployee"
            :feedback="errors"
            />
          <form-action-buttons
            @submit="update"
            @cancel="backToDetails"
            />
        </b-col>

      </b-row>
    </b-container>

    <vs-loader v-else />

  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  BContainer,
  BRow,
  BCol,
} from 'bootstrap-vue';

import Avatar from '@/components/ui/Avatar.vue';
import EmployeeForm from '@/components/views/cadre/Form.vue';
import FormActionButtons from '@/components/ui/FormActionButtons.vue';
import VsLoader from '@/components/ui/vs-loader/VsLoader.vue';
import { EditSuccess, EditError } from '@feedback/module/cadre';
import RemoteResourceManager from '@/libs/RemoteResourceManager';

export default {
  data: () => ({ newEmployee: {}, errors: {} }),
  methods: {
    backToDetails() {
      this.$router.push({ name: 'cadre.employee.details' });
    },
    update() {
      this.$store
        .dispatch('cadre/employees/update', { ...this.newEmployee })
        .then(({ data }) => {
          const employee = data.data;
          this.errors = {};

          this.$showFeedback(EditSuccess, {
            name: employee.full_name,
          }, () => this.$router.push({ name: 'cadre.employee.details' }));

          RemoteResourceManager.getInstance()
            .notify('cadre.employee');

          if (employee.user_core_id) {
            RemoteResourceManager.getInstance()
              .notify('core.user');
          }
        })
        .catch((err) => {
          this.errors = err?.response?.data?.errors;
          this.$showFeedback(EditError);
        });
    },
  },
  components: {
    Avatar,
    BContainer,
    BRow,
    BCol,
    EmployeeForm,
    FormActionButtons,
    VsLoader,
  },
  computed: {
    ...mapGetters({
      currentEmployee: 'cadre/employees/current',
    }),
    employeeModel: () => ({ ...this.currentEmployee }),
  },
};
</script>
