<template>
  <div class="cadre-edit-employee-main">

    <h3>Ustawienia konta</h3>
    <hr>

    <template v-if="hasAccount">
      Użytkownik posiada konto w serwisie.
    </template>

    <template v-else>
      <vs-loader v-if="isCreatingAccount" full style="top: 0; left: 0" />
      Użytkownik nie posiada konta w serwisie.

      <div style="margin-top: 20px">
        <vs-button
          vs-variant="primary"
          @click="createAccount"
        >
          Utwórz konto
        </vs-button>
      </div>
    </template>

    <template
      v-if="hasAccount && canEditPermissions"
    >
      <h3 class="mt-4">Uprawnienia</h3>
      <hr>
      <br>
      <vs-button
        vs-variant="primary"
        @click="savePermissions"
        :loading="isSaving"
      >
        Zapisz uprawnienia
      </vs-button>
      <br>
      <br>

      <vs-loader
        v-if="!isFetched"
        text="Pobieranie informacji o uprawnieniach"
      />

      <vs-permission-editor
        v-show="isFetched"
        :fetched="fetchedEnabled"
        @update:granted="updateGranted"
        @update:denied="updateDenied"
      />
    </template>

  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { computed, ref, watch } from 'vue';
import VsButton from '@/components/ui/vs-button/VsButton.vue';
import useCoreApi from '@/hooks/useCoreApi';
import store from '@/store';
import VsLoader from '@/components/ui/vs-loader/VsLoader.vue';
import showToast from '@/libs/toasts';
import {
  FetchUserPermissionsError,
  SaveUserPermissionsError,
  SaveUserPermissionsSuccess,
  CreateUserAccountSuccess,
  CreateUserAccountError,
} from '@/libs/toasts/feedback/module/core';
import VsPermissionEditor from '@/components/ui/vs-permission-editor/VsPermissionEditor.vue';
import useCadreApi from '@/hooks/cadre/useCadreApi';

export default {
  setup() {
    const userPermissions = ref({});
    const fetchedEnabled = ref([]);
    const isFetched = ref(false);
    const employee = computed(() => store.getters['cadre/employees/current']);
    const employeeUserId = computed(() => employee.value.user_core_id);
    const hasPermission = store.getters['auth/userHasPermission'];
    const canEditPermissions = computed(() => hasPermission('permission', 'core.user'));
    const hasAccount = computed(() => !!employee.value.user_core_id);

    const fetchUserPermissions = () => {
      if (!canEditPermissions.value) return;

      useCoreApi()
        .fetchUserPermissions(employeeUserId.value)
        .then(({ data }) => {
          fetchedEnabled.value = [
            ...data.data.permissions.map((p) => ({
              id: p.id,
              name: p.name,
              disabled: false,
            })),
            ...data.data.forbidden_permissions.map((p) => ({
              id: p.id,
              name: p.name,
              disabled: true,
            })),
          ];

          isFetched.value = true;
        })
        .catch(() => {
          showToast(FetchUserPermissionsError);
        });
    };

    if (hasAccount.value) fetchUserPermissions();
    watch(hasAccount, (v) => {
      if (v) fetchUserPermissions();
    });

    const isSaving = ref(false);
    const savePermissions = () => {
      isSaving.value = true;

      useCoreApi()
        .assignToUser(employeeUserId.value, {
          permissions: userPermissions.value.enabled,
          forbidden_permissions: userPermissions.value.disabled,
        })
        .then(() => {
          isSaving.value = false;
          showToast(SaveUserPermissionsSuccess);
        })
        .catch(() => {
          isSaving.value = false;
          showToast(SaveUserPermissionsError);
        });
    };

    const updateGranted = (val) => {
      userPermissions.value.enabled = val;
    };
    const updateDenied = (val) => {
      userPermissions.value.disabled = val;
    };

    const isCreatingAccount = ref(false);
    const createAccount = () => {
      if (!employee.value.id) return;
      isCreatingAccount.value = true;

      useCadreApi().employee
        .createAccount(employee.value.id)
        .then(() => {
          store.dispatch('cadre/employees/fetchOne', { employeeId: employee.value.id, force: true });
          showToast(CreateUserAccountSuccess);
        })
        .catch(() => {
          showToast(CreateUserAccountError);
        })
        .finally(() => {
          isCreatingAccount.value = false;
        });
    };

    return {
      userPermissions,
      savePermissions,
      fetchedEnabled,
      isFetched,
      isSaving,
      updateGranted,
      updateDenied,
      canEditPermissions,
      hasAccount,
      isCreatingAccount,
      createAccount,
    };
  },
  computed: {
    ...mapGetters({
      currentEmployee: 'cadre/employees/current',
    }),
  },
  components: {
    VsButton, VsLoader, VsPermissionEditor,
  },
};
</script>
