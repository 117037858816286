var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "cadre-edit-employee-main"
  }, [Object.keys(_vm.currentEmployee).length ? _c('b-container', [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('div', [_c('avatar', {
    attrs: {
      "lg": "",
      "user": _vm.currentEmployee,
      "displayUserIcon": !!_vm.currentEmployee.user_core_id
    }
  })], 1)]), _c('b-col', {
    attrs: {
      "cols": "8"
    }
  }, [Object.keys(_vm.currentEmployee).length ? _c('employee-form', {
    attrs: {
      "hide-user-checkbox": "",
      "model": _vm.currentEmployee,
      "feedback": _vm.errors
    },
    model: {
      value: _vm.newEmployee,
      callback: function ($$v) {
        _vm.newEmployee = $$v;
      },
      expression: "newEmployee"
    }
  }) : _vm._e(), _c('form-action-buttons', {
    on: {
      "submit": _vm.update,
      "cancel": _vm.backToDetails
    }
  })], 1)], 1)], 1) : _c('vs-loader')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }