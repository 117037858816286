var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "cadre-edit-employee-main"
  }, [_c('h3', [_vm._v("Ustawienia konta")]), _c('hr'), _vm.hasAccount ? [_vm._v(" Użytkownik posiada konto w serwisie. ")] : [_vm.isCreatingAccount ? _c('vs-loader', {
    staticStyle: {
      "top": "0",
      "left": "0"
    },
    attrs: {
      "full": ""
    }
  }) : _vm._e(), _vm._v(" Użytkownik nie posiada konta w serwisie. "), _c('div', {
    staticStyle: {
      "margin-top": "20px"
    }
  }, [_c('vs-button', {
    attrs: {
      "vs-variant": "primary"
    },
    on: {
      "click": _vm.createAccount
    }
  }, [_vm._v(" Utwórz konto ")])], 1)], _vm.hasAccount && _vm.canEditPermissions ? [_c('h3', {
    staticClass: "mt-4"
  }, [_vm._v("Uprawnienia")]), _c('hr'), _c('br'), _c('vs-button', {
    attrs: {
      "vs-variant": "primary",
      "loading": _vm.isSaving
    },
    on: {
      "click": _vm.savePermissions
    }
  }, [_vm._v(" Zapisz uprawnienia ")]), _c('br'), _c('br'), !_vm.isFetched ? _c('vs-loader', {
    attrs: {
      "text": "Pobieranie informacji o uprawnieniach"
    }
  }) : _vm._e(), _c('vs-permission-editor', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isFetched,
      expression: "isFetched"
    }],
    attrs: {
      "fetched": _vm.fetchedEnabled
    },
    on: {
      "update:granted": _vm.updateGranted,
      "update:denied": _vm.updateDenied
    }
  })] : _vm._e()], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }