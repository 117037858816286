var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "employee-form"
  }, [_c('b-form', {
    on: {
      "submit": function ($event) {
        $event.preventDefault();
      }
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": _vm.$t('cadre.employee.edit.field.forename'),
      "label-cols-md": "3",
      "invalid-feedback": _vm.errors.forename,
      "state": _vm.errors.forename === null
    }
  }, [_c('b-input-group', [_c('b-form-input', {
    attrs: {
      "placeholder": _vm.$t('cadre.employee.edit.field.forename')
    },
    model: {
      value: _vm.forename,
      callback: function ($$v) {
        _vm.forename = $$v;
      },
      expression: "forename"
    }
  })], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": _vm.$t('cadre.employee.edit.field.surname'),
      "label-cols-md": "3",
      "invalid-feedback": _vm.errors.surname,
      "state": _vm.errors.surname === null
    }
  }, [_c('b-input-group', [_c('b-form-input', {
    attrs: {
      "placeholder": _vm.$t('cadre.employee.edit.field.surname')
    },
    model: {
      value: _vm.surname,
      callback: function ($$v) {
        _vm.surname = $$v;
      },
      expression: "surname"
    }
  })], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": _vm.$t('cadre.employee.edit.field.shortcode'),
      "label-cols-md": "3",
      "invalid-feedback": _vm.errors.short_code,
      "state": _vm.errors.short_code === null
    }
  }, [_c('b-input-group', [_c('b-form-input', {
    attrs: {
      "placeholder": _vm.$t('cadre.employee.edit.field.shortcode')
    },
    model: {
      value: _vm.employee.short_code,
      callback: function ($$v) {
        _vm.$set(_vm.employee, "short_code", $$v);
      },
      expression: "employee.short_code"
    }
  })], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": _vm.$t('cadre.employee.edit.field.phone'),
      "label-cols-md": "3",
      "invalid-feedback": _vm.errors.phone_number,
      "state": _vm.errors.phone_number === null
    }
  }, [_c('b-input-group', [_c('b-form-input', {
    attrs: {
      "placeholder": _vm.$t('cadre.employee.edit.field.phone')
    },
    model: {
      value: _vm.employee.phone_number,
      callback: function ($$v) {
        _vm.$set(_vm.employee, "phone_number", $$v);
      },
      expression: "employee.phone_number"
    }
  })], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": _vm.$t('cadre.employee.edit.field.email'),
      "label-cols-md": "3",
      "invalid-feedback": _vm.errors.email,
      "state": _vm.errors.email === null
    }
  }, [_c('b-input-group', [_c('b-form-input', {
    attrs: {
      "placeholder": _vm.$t('cadre.employee.edit.field.email')
    },
    model: {
      value: _vm.employee.email,
      callback: function ($$v) {
        _vm.$set(_vm.employee, "email", $$v);
      },
      expression: "employee.email"
    }
  })], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": _vm.$t('cadre.employee.edit.field.status'),
      "label-cols-md": "3",
      "invalid-feedback": _vm.errors.status,
      "state": _vm.errors.status !== null
    }
  }, [_c('cadre-status-picker', {
    model: {
      value: _vm.employee.status_id,
      callback: function ($$v) {
        _vm.$set(_vm.employee, "status_id", $$v);
      },
      expression: "employee.status_id"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": _vm.$t('cadre.employee.edit.field.contract_beginning'),
      "label-cols-md": "12",
      "invalid-feedback": _vm.errors.contract_start,
      "state": _vm.errors.contract_start === null
    }
  }, [_c('vs-datepicker', {
    model: {
      value: _vm.contractStart,
      callback: function ($$v) {
        _vm.contractStart = $$v;
      },
      expression: "contractStart"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": _vm.$t('cadre.employee.edit.field.contract_end'),
      "label-cols-md": "12",
      "invalid-feedback": _vm.errors.contract_end,
      "state": _vm.errors.contract_end === null
    }
  }, [_c('vs-datepicker', {
    attrs: {
      "placeholder": "Czas nieokreślony",
      "reset-button": "",
      "label-reset-button": "Czas nieokreślony"
    },
    model: {
      value: _vm.contractEnd,
      callback: function ($$v) {
        _vm.contractEnd = $$v;
      },
      expression: "contractEnd"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": _vm.$t('cadre.employee.edit.field.form_of_employment'),
      "label-cols-md": "3",
      "invalid-feedback": _vm.errors.form_of_employment,
      "state": !_vm.errors.form_of_employment
    }
  }, [_c('cadre-employment-form-picker', {
    attrs: {
      "multiple": ""
    },
    model: {
      value: _vm.employmentFormsArray,
      callback: function ($$v) {
        _vm.employmentFormsArray = $$v;
      },
      expression: "employmentFormsArray"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": _vm.$t('cadre.employee.edit.field.medical_examinations'),
      "label-cols-md": "3",
      "invalid-feedback": _vm.errors.medical_examinations,
      "state": _vm.errors.medical_examinations === null
    }
  }, [_c('b-form-checkbox', {
    attrs: {
      "switch": "",
      "size": "lg"
    },
    model: {
      value: _vm.employee.medical_exam_required,
      callback: function ($$v) {
        _vm.$set(_vm.employee, "medical_exam_required", $$v);
      },
      expression: "employee.medical_exam_required"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": _vm.$t('cadre.employee.edit.field.position'),
      "label-cols-md": "3",
      "invalid-feedback": _vm.errors.position_id,
      "state": !_vm.errors.position_id
    }
  }, [_c('cadre-position-picker', {
    model: {
      value: _vm.employee.position_id,
      callback: function ($$v) {
        _vm.$set(_vm.employee, "position_id", $$v);
      },
      expression: "employee.position_id"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": _vm.$t('cadre.employee.edit.field.supervisor'),
      "label-cols-md": "3",
      "invalid-feedback": _vm.errors.supervisor,
      "state": _vm.errors.supervisor === undefined
    }
  }, [_c('cadre-employee-picker', {
    model: {
      value: _vm.employee.supervisor_id,
      callback: function ($$v) {
        _vm.$set(_vm.employee, "supervisor_id", $$v);
      },
      expression: "employee.supervisor_id"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": _vm.$t('cadre.employee.edit.field.region'),
      "label-cols-md": "3",
      "invalid-feedback": _vm.errors.region,
      "state": _vm.errors.company_branch_id !== null
    }
  }, [_c('core-branch-picker', {
    model: {
      value: _vm.employee.company_branch_id,
      callback: function ($$v) {
        _vm.$set(_vm.employee, "company_branch_id", $$v);
      },
      expression: "employee.company_branch_id"
    }
  })], 1)], 1), !_vm.hideUserCheckbox ? _c('b-col', [_c('b-form-group', {
    attrs: {
      "label": _vm.$t('cadre.employee.edit.field.createAccount'),
      "label-cols-md": "3",
      "invalid-feedback": _vm.errors.should_create_user,
      "state": _vm.errors.should_create_user === null
    }
  }, [_c('b-form-checkbox', {
    attrs: {
      "switch": "",
      "size": "lg"
    },
    model: {
      value: _vm.employee.should_create_user,
      callback: function ($$v) {
        _vm.$set(_vm.employee, "should_create_user", $$v);
      },
      expression: "employee.should_create_user"
    }
  })], 1)], 1) : _vm._e()], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }