<script>
import Vue, { h } from 'vue';
import VsPickerVue from '@/components/ui/vs-picker/VsPicker.vue';
import { createPickerAttributes, emits } from '@/components/ui/vs-picker';
import useCadreContractStore from '@/stores/module/cadre/useCadreContractStore';

export default Vue.component('CadreEmploymentFormPicker', {
  emits,
  setup(_, ctx) {
    const store = useCadreContractStore();

    return () => h(VsPickerVue, createPickerAttributes(ctx, {
      placeholder: 'Wybierz formę zatrudnienia z listy',
      reduce: (option) => option.id,
      options: store.state,
      ...ctx.attrs,
    }));
  },
  components: { VsPickerVue },
});
</script>
