var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "form-action-buttons"
  }, [_c('div', {
    staticClass: "button-group"
  }, [_c('vs-button', {
    attrs: {
      "vs-variant": "light"
    },
    on: {
      "click": _vm.cancel
    }
  }, [_vm._v(" " + _vm._s(_vm.labelCancel) + " ")]), _c('vs-button', {
    attrs: {
      "vs-variant": "primary"
    },
    on: {
      "click": _vm.submit
    }
  }, [_vm._v(" " + _vm._s(_vm.labelSubmit) + " ")])], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }