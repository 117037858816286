var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    staticClass: "cadre-edit-employee"
  }, [_c('b-tabs', {
    attrs: {
      "pills": "",
      "card": ""
    }
  }, [_c('b-tab', {
    attrs: {
      "active": ""
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_c('feather-icon', {
          attrs: {
            "icon": "SlidersIcon"
          }
        }), _vm._v(" Ustawienia pracownika ")];
      },
      proxy: true
    }])
  }, [_c('main-settings')], 1), _c('b-tab', {
    attrs: {
      "lazy": ""
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_c('feather-icon', {
          attrs: {
            "icon": "UserIcon"
          }
        }), _vm._v(" Ustawienia konta i uprawnienia ")];
      },
      proxy: true
    }])
  }, [_c('account-settings')], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }